import { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Text,
  Center,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useModifiedTranslation } from "../../hooks/useModifiedTranslation";

export function ErrorDialog(props: {
  messageKey: string;
  navigatePath?: string;
}): ReactElement {
  const { i18n } = useTranslation();
  const t = useModifiedTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [navigatePath, setNavigatePath] = useState(`/${i18n.language}/home`);

  useEffect(() => {
    if (props.messageKey !== "") {
      setIsOpen(true);
    }
  }, [props.messageKey]);

  useEffect(() => {
    if (props.navigatePath) {
      setNavigatePath(props.navigatePath);
    }
  }, [props.navigatePath]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent margin="auto" backgroundColor="common.base">
        <ModalBody>
          <Center flexDirection="column" mt={6}>
            <Text whiteSpace="pre-wrap" textAlign="center">
              {t(props.messageKey)}
            </Text>
          </Center>
          <Center>
            <Button
              mt={12}
              mb={4}
              as={Link}
              to={navigatePath}
              replace
              variant="btn_primary"
              height="60px"
              width="300px"
            >
              OK
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
