import { useAtomValue } from "jotai";
import { LanguageLabelsAtom } from "../store";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

// 翻訳テキストを変更するカスタムフック
export const useModifiedTranslation = (): ((text: string) => string) => {
  const { t: i18nT } = useTranslation();
  const labels = useAtomValue(LanguageLabelsAtom);
  const currentLanguage = i18n.language;

  const t = useCallback(
    (text: string): string => {
      let modifiedText = i18nT(text);

      const replacementRules = labels[currentLanguage] || {};

      // {}で囲まれた特定フレーズをルールに基づいて置換
      Object.entries(replacementRules).forEach(([key, replacement]) => {
        modifiedText = modifiedText.replace(
          new RegExp(`{${key}}`, "g"),
          replacement
        );
      });

      return modifiedText;
    },
    [currentLanguage, i18nT, labels]
  );

  return t;
};
