import { ReactElement, useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Calibration } from "./pages/calibration";
import { CalibrationV2 } from "./pages/calibrationV2";
import { Home } from "./pages/home";
import { ProtectedQuestionnaires } from "./pages/engines/[engineType]/questionnaires";
import { SelectRecType } from "./pages/engines/[engineType]/select-rectype";
import { ProtectedRecording } from "./pages/engines/[engineType]/recording";
import { Result } from "./pages/engines/[engineType]/results/[fileId]";
import { Setting } from "./pages/setting";
import { QueryLoginResult } from "./pages/query-login-result";
import { AnalysisResult } from "./pages/analysis-result";
import { NotFound } from "./pages/404";
import { AnalysisError } from "./pages/engines/[engineType]/analysis-error";
import { useTranslation } from "react-i18next";
import { Engines } from "./pages/engines/";
import { EngineType } from "./pages/engines/[engineType]";
import { Login } from "./pages/login";
import { QueryLogin } from "./pages/query-login";
import { useAtom } from "jotai";
import {
  accessTokenInfoAtom,
  companyAccessTokenAtom,
  employeeInfoAtom,
  isQueryModeAtom,
} from "./store";
import dayjs from "dayjs";
import { PasswordReset } from "./pages/password-reset";
import { ResetId } from "./pages/password-reset/[resetId]";
import { AccountUnlocked } from "./pages/account-unlocked";
import { PasswordChange } from "./components/molecules/PasswordChange";
import { isValidLanguageType } from "./types";
import { ColorLayout } from "./pages/color-layout";
import { SentryRoutes } from "../sentry";
import * as Sentry from "@sentry/react";
import { APP_TYPE } from "./environments";
import { HomePoC } from "./pages/home-poc";
import { checkWafResponse } from "./utils/checkWafResponse";
import { TwooCaCallback } from "./pages/callback/twooca-callback";

// ページ遷移時にスクロール位置をリセットする
function ScrollToTop(): ReactElement | null {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
}

function ProtectedRoutes(): ReactElement {
  const { i18n } = useTranslation();
  const [employeeInfo] = useAtom(employeeInfoAtom);
  const [tokenInfo] = useAtom(accessTokenInfoAtom);
  let isValid;
  if (employeeInfo.employee_id) {
    const now = dayjs();
    isValid = tokenInfo && dayjs(tokenInfo.expired_at).isAfter(now);
    Sentry.setUser({ email: employeeInfo.employee_id });
  }

  return isValid ? <Outlet /> : <Navigate to={`/${i18n.language}/login`} />;
}

function AppRoot(): ReactElement {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isValidLanguageType(lang)) {
      if (i18n.language !== lang) {
        i18n.changeLanguage(lang).then();
      }
    } else {
      navigate("/404", { replace: true });
    }
  }, [i18n, lang, navigate]);

  const location = useLocation();

  // WAFでメンテナンス時のアクセス制限(ステータスコード503)がかかっていたらリロードする
  // (WebACLSのカスタムレスポンスが表示される)
  useEffect(() => {
    const checkAndReload = async (): Promise<void> => {
      try {
        const status = await checkWafResponse(location.pathname);
        if (status === 503) {
          window.location.reload();
        }
      } catch (error) {
        console.error("WAF確認エラー:", error);
      }
    };

    checkAndReload();
  }, [location.pathname]);

  return <Outlet />;
}

function App(): ReactElement {
  const { i18n } = useTranslation();
  const defaultLanguage = isValidLanguageType(i18n.language)
    ? i18n.language
    : "en";
  const defaultPath = `/${defaultLanguage}/home`;

  // クエリログイン用の遷移先アドレス
  const queryLoginPath = `/${defaultLanguage}/query-login`;

  // クエリログイン判定用
  const [, setQueryLogin] = useAtom(isQueryModeAtom);
  // クエリログイン時の「company_token」値保持用
  const [, setCompanyAccessToken] = useAtom(companyAccessTokenAtom);

  // クエリログイン時の「company_token」値の取得
  const location = useLocation().search;
  const urlParams = new URLSearchParams(location);
  const companyAccessTokenInfo = urlParams.get("company_token");

  useEffect(() => {
    return () => {
      if (companyAccessTokenInfo) {
        setCompanyAccessToken(companyAccessTokenInfo);
        setQueryLogin(true);
      }
    };
  }, [companyAccessTokenInfo, setQueryLogin, setCompanyAccessToken]);

  // console.log(isQueryLogin && companyAccessToken);

  return (
    <>
      <ScrollToTop />
      <SentryRoutes>
        {/* クエリログインか否かの判定 */}
        <Route
          path="/"
          element={
            companyAccessTokenInfo ? (
              <Navigate replace to={queryLoginPath} />
            ) : (
              <Navigate replace to={defaultPath} />
            )
          }
        />
        <Route path="twooca">
          <Route path="callback" element={<TwooCaCallback />} />
        </Route>
        <Route path=":lang" element={<AppRoot />}>
          <Route index element={<Navigate replace to={defaultPath} />} />
          {/* クエリログイン */}
          <Route path="query-login" element={<QueryLogin />} />
          <Route path="login" element={<Login />} />
          <Route path="account-unlocked" element={<AccountUnlocked />} />
          <Route path="password-reset">
            <Route index element={<PasswordReset />} />
            <Route path=":resetId" element={<ResetId />} />
          </Route>

          <Route element={<ProtectedRoutes />}>
            {APP_TYPE === "base" && <Route path="home" element={<Home />} />}
            {APP_TYPE === "poc" && <Route path="home" element={<HomePoC />} />}
            <Route path="calibration" element={<Calibration backButton />} />
            <Route
              path="calibration_new"
              element={<CalibrationV2 backButton />}
            />
            {/* 設定画面 */}
            <Route path="setting" element={<Setting />} />
            {/* パスワード設定画面 */}
            <Route path="setting/password" element={<PasswordChange />} />
            {/* カラー/レイアウト設定画面 */}
            <Route path="setting/color_layout" element={<ColorLayout />} />
            {/* クエリログイン時の音声録音解析完了画面 */}
            <Route path="query-login-result" element={<QueryLoginResult />} />
            {/* 解析結果グラフ画面 */}
            <Route path="analysis-result" element={<AnalysisResult />} />
            <Route path="engines">
              <Route index element={<Engines />} />
              <Route path=":engineType" element={<EngineType />}>
                <Route index element={<Navigate replace to="/404" />} />
                <Route
                  path="questionnaires"
                  element={<ProtectedQuestionnaires />}
                />
                {/* 録音種別選択画面 */}
                <Route path="select-rectype" element={<SelectRecType />} />
                <Route path="recording" element={<ProtectedRecording />} />
                <Route path="analysis-error" element={<AnalysisError />} />
                <Route path="results">
                  <Route index element={<Navigate replace to="/404" />} />
                  <Route path=":fileId" element={<Result />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </SentryRoutes>
    </>
  );
}

export default App;
