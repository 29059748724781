export type AnalysisEngineType = "Mi1";
export type TokenEngineType = "mi1";

export function isValidAnalysisEngineType(
  engineType: unknown
): engineType is AnalysisEngineType {
  return typeof engineType === "string" && engineType === "Mi1";
}

export type LanguageType = "ja" | "en";
export function isValidLanguageType(lang: unknown): lang is LanguageType {
  if (typeof lang !== "string") return false;
  return lang === "ja" || lang === "en";
}

export function isObject(x: unknown): x is Record<string, unknown> {
  return typeof x === "object" && x != null && !Array.isArray(x);
}

export type AppApiError = {
  status_code?: number;
  detail: string | Record<string, string> | Array<Record<string, string>>;
};
export function isValidAppApiError(err: unknown): err is AppApiError {
  if (!isObject(err)) {
    return false;
  }
  return (
    (typeof err.status_code !== "number" ||
      typeof err.status_code !== "undefined") &&
    (typeof err.detail === "string" ||
      isObject(err.detail) ||
      Array.isArray(err.detail))
  );
}

export type RecordingState =
  | "INITIALIZING"
  | "IDLE"
  | "PREPARING"
  | "RECORDING"
  | "STOPPING"
  | "COMPLETED";

export type InitializingState =
  | "UNINITIALIZED"
  | "INITIALIZING"
  | "INITIALIZED"
  | "DESTROYING";

export type ReadyState =
  | "UNINITIALIZED"
  | "INITIALIZING"
  | "READY"
  | "FINISHED";

export type ResultMainParam = {
  title: string;
  value: number | string | undefined;
  border: string;
};

export type ResultSubParam = {
  title: string;
  value: number | undefined;
};

export type ResultSupplementaryInfo = {
  title: string;
  value: number | undefined;
};

export type ResultDiagramIndex = {
  title: string;
  width: string;
};

export type ResultDiagramParam = {
  width: string;
  value: string | number;
};

export type ResultDashboardData = {
  mainParam: ResultMainParam;
  subParams: ResultSubParam[];
  supplementaryInfo: ResultSupplementaryInfo[];
  diagramIndex: ResultDiagramIndex[];
  diagramParams: ResultDiagramParam[][];
};

export type AnalysisErrorReason = {
  code: string;
  message: string;
};

export function isValidAnalysisErrorReason(
  reason: unknown
): reason is AnalysisErrorReason {
  if (!isObject(reason)) return false;
  return typeof reason.code === "string" && typeof reason.message === "string";
}

export type SelectionLoginUser = {
  id?: number;
  email: string;
  password: string;
  nickname: string;
  color: string;
};

export type RecordedData = {
  data: Float32Array;
  samplingRate: number;
  phrase: string;
  isSkipped: boolean;
  co1VoiceType?: string;
  dysphagiaVoiceTypes?: string;
};

export type AccessToken = {
  access_token: string;
  expires_in: number;
};

export type AnalysisState = "IDLE" | "UPLOADING" | "ANALYZING";

export type SoundVisualizerRef = {
  draw: (
    rawDara: number,
    isTooLoud: boolean,
    isDetectedVolume: boolean
  ) => void;
};

export enum Trend {
  Up,
  Normal,
  Down,
}

export type ResultChartData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  date: number;
  vitality100?: number;
  mental_activity100?: number;
  mental_activity_reliability?: boolean;
  mi1_result?: number;
  mi1_result_reliability?: boolean;
  datingType?: string;
};

export type CustomDotType = {
  dotColor: string;
  strokeWidth: string;
  thresholdAlertValue: number;
  value?: string;
  r?: number;
  name?: string;
  strokeLinecap?: string;
  fillOpacity?: number;
  stroke?: string;
  fill?: string;
  width?: number;
  height?: number;
  dataKey?: string;
  cx?: number;
  cy?: number;
  index?: number;
  payload?: ResultChartData;
};

export type CheckVolumeResult =
  | "OK"
  | "Error.allDataIsSilent"
  | "Error.belowVolumeThreshold"
  | "Error.cannotDetectVoice";

export type themeType =
  | "blue_dark"
  | "blue_light"
  | "blue_gray_dark"
  | "blue_gray_light"
  | "bluesky_dark"
  | "bluesky_light"
  | "brown_dark"
  | "brown_light"
  | "green_dark"
  | "green_light"
  | "pink_dark"
  | "pink_light"
  | "sakura_dark"
  | "sakura_light"
  | "yellow_dark"
  | "yellow_light"
  | "yellow_green_dark"
  | "yellow_green_light"
  | "yellow_orange_dark"
  | "yellow_orange_light";

export type PhraseText = {
  title: string;
  phrase: string;
  helperText?: string;
  baseTextSize?: string;
  mdTextSize?: string;
  co1VoiceType?: string;
};

export type LoginFormData = {
  user_id: string;
  password: string;
};

export type CredentialResponseType = {
  credential: string;
};

export type TwoocaRequestData = {
  code: string;
};

export type LoginErrorCode =
  | "INVALID_TOKEN"
  | "INVALID_GOOGLE_TOKEN"
  | "INVALID_TWOOCA_TOKEN"
  | "ENGINE_DISABLED";

export enum LoginMethod {
  LoginForm = "loginForm",
  GoogleAuth = "googleAuth",
  TwoocaAuth = "twoocaAuth",
}

export interface AuthData {
  [LoginMethod.LoginForm]: LoginFormData;
  [LoginMethod.GoogleAuth]: CredentialResponseType;
  [LoginMethod.TwoocaAuth]: TwoocaRequestData;
}
