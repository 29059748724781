/* eslint-disable react/prop-types */
import { ReactElement, useEffect, useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Link as ChakraLink,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { IoAdd, IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAtom } from "jotai";
import {
  employeeInfoAtom,
  employeeInfoInitial,
  passwordChangeStatusAtom,
  isGoogleSSOAtom,
  loginImageInfoAtom,
} from "../store";
import {
  SelectionLoginUser,
  LoginErrorCode,
  LoginFormData,
  LoginMethod,
} from "../types";
import { Layout } from "../components/atoms/Layout";
import { HIDE_LANGUAGE_SELECTION } from "../environments";
import { ColorPicker } from "../components/molecules/ColorPicker";
import { DB, useAddUserToDb } from "../utils/db";
import { useLiveQuery } from "dexie-react-hooks";
import { SelectionLoginUserButton } from "../components/atoms/SelectionLoginUserButton";
import { LoadingOverlay } from "../components/atoms/LoadingOverlay";
import {
  IdInput,
  PasswordInput,
  TextInput,
} from "../components/atoms/AppInputs";
import { SimpleAlertDialog } from "../components/atoms/SimpleAlertDialog";
import { LanguageSelector } from "../components/atoms/LanguageSelector";
import { PrimarySwitch } from "../components/atoms/PrimarySwitch";
import { atomWithStorage } from "jotai/utils";
import { checkEngineEnabled } from "../utils/checkEnableEngine";

import { useWindowSize } from "../hooks/useWindowSize";
import { useSetSetting } from "../utils/setting";
import { homeSplashDisplayAtom } from "../components/atoms/ResultHome/HomeAtoms";
import { GoogleLoginButton } from "../components/molecules/GoogleLoginButton";
import { BannarImage } from "../components/atoms/BannarImage";
import { useGetAccessToken, useSaveTokenToStorage } from "../utils/token";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";
import { VersionCheckModal } from "../components/molecules/VersionCheckModal";
import { TwooCaLoginButton } from "../components/molecules/TwooCaLoginButton";
import { LoginErrorModal } from "../components/molecules/LoginErrorModal";

const COLOR_LIST = [
  { color: "#F9E1E1", label: "pink" },
  { color: "#E6B7EF", label: "purple" },
  { color: "#AEADE6", label: "indigo" },
  { color: "#8FE3E1", label: "sky-blue" },
  { color: "#A0E188", label: "green" },
  { color: "#E6E681", label: "yellow" },
];
const DEFAULT_COLOR_INDEX = 0;

function NormalLoginForm(props: {
  onInvalidUserInfo: (errorCode: LoginErrorCode) => void;
}): ReactElement {
  // GoogleSSO中ならキャンセルする
  window.google.accounts.id.cancel();
  const [, setIsGoogleSSO] = useAtom(isGoogleSSOAtom);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginFormData>();
  const [isCheckedToSaveUser, setIsCheckedToSaveUser] = useState(false);
  const [nickname, setNickname] = useState("");
  const [selectedColor, setSelectedColor] = useState(
    COLOR_LIST[DEFAULT_COLOR_INDEX].color
  );
  const t = useModifiedTranslation();
  const saveToken = useSaveTokenToStorage();
  const getAccessToken = useGetAccessToken();
  // アプリ設定一括取得（/analysis/app/settings）と状態保持 前準備
  const setSettingInfo = useSetSetting();
  const addUserToDb = useAddUserToDb();

  const onSubmit: SubmitHandler<LoginFormData> = async (data) => {
    // PSTアカウントによる代理ログイン機能対応
    const token = await getAccessToken(
      LoginMethod.LoginForm,
      props.onInvalidUserInfo,
      data
    );

    if (!token) return;

    // 選択ログインにユーザ追加
    if (isCheckedToSaveUser) {
      await addUserToDb({
        email: data.user_id,
        password: data.password,
        color: selectedColor,
        nickname,
      });
    }

    if (!checkEngineEnabled(token.access_token)) {
      props.onInvalidUserInfo("ENGINE_DISABLED");
      return;
    }
    saveToken(token);

    // アプリ設定一括取得（/analysis/app/settings）と状態保持 実施
    const user_id = data.user_id.split(",").pop();
    if (user_id) setSettingInfo(user_id);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} minHeight="500px">
      <Flex direction="column" align="center">
        <Flex flexDirection="column" p={{ base: 5, md: 10 }}>
          <IdInput
            {...register("user_id", { required: true })}
            mb={5}
            multiple // PSTアカウントによる代理ログイン機能対応
          />
          <PasswordInput
            input={{ ...register("password", { required: true }) }}
          />

          {/* 選択ログイン保存スイッチ */}
          <FormControl
            my={3}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <FormLabel htmlFor="email-alerts" mb={0} mr={1} fontSize={13}>
              {t("Login.addToSelectionLoginUsers")}
            </FormLabel>
            <PrimarySwitch
              id="email-alerts"
              onChange={(e) => setIsCheckedToSaveUser(e.target.checked)}
            />
          </FormControl>

          {/* Collapse内の要素のOutlineが途切れてしまうため、要素を少し大きしておいてpaddingを指定する */}
          {/* https://github.com/chakra-ui/chakra-ui/issues/6049 */}
          <Box width="calc(100% + 4px)" ml="-2px">
            <Collapse in={isCheckedToSaveUser} unmountOnExit>
              <Box px="2px">
                <TextInput
                  mt={1}
                  mb={5}
                  placeholder={t("Login.nickname")}
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                  bg="common.base"
                />
                <Text mb={1} textAlign="center">
                  {t("Login.selectColor")}
                </Text>
                <ColorPicker
                  justifyContent="center"
                  spacing={{ base: 2, md: 5 }}
                  colorList={COLOR_LIST}
                  defaultIndex={DEFAULT_COLOR_INDEX}
                  onColorChange={(colorIndex) =>
                    setSelectedColor(COLOR_LIST[colorIndex].color)
                  }
                />
              </Box>
            </Collapse>
          </Box>

          <ChakraLink
            as={Link}
            to="../password-reset"
            color="primary.theme_lv1"
            mx="auto"
            my={6}
          >
            {t("Login.forgotPassword")}
          </ChakraLink>
        </Flex>
        <Button
          type="submit"
          variant="btn_primary"
          w="90%"
          size="lg"
          mb={10}
          isLoading={isSubmitting}
          onClick={() => setIsGoogleSSO(false)}
        >
          {t("Login.login")}
        </Button>
        <Flex gap={2} justifyContent="space-evenly" flexWrap="wrap" w="90%">
          <GoogleLoginButton onInvalidUserInfo={props.onInvalidUserInfo} />
          {["beta"].includes(import.meta.env.MODE) && <TwooCaLoginButton />}
        </Flex>
      </Flex>
    </Box>
  );
}

function SelectionLogin(props: {
  onInvalidUserInfo: (errorCode: LoginErrorCode) => void;
}): ReactElement {
  // GoogleSSO中ならキャンセルする
  window.google.accounts.id.cancel();
  const userList = useLiveQuery(() => DB.loadAllUsers());
  const [isLoginProcessing, setIsLoginProcessing] = useState(false);
  const [isAdditionFormOpen, setIsAdditionFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userToDelete, setUserToDelete] = useState<SelectionLoginUser | null>(
    null
  );
  const [userToEdit, setUserToEdit] = useState<SelectionLoginUser | null>(null);
  const saveToken = useSaveTokenToStorage();
  const getAccessToken = useGetAccessToken();
  const t = useModifiedTranslation();
  // アプリ設定一括取得（/analysis/app/settings）と状態保持 前準備
  const setSettingInfo = useSetSetting();

  const login = async (selectionUser: SelectionLoginUser): Promise<void> => {
    const user: LoginFormData = {
      user_id: selectionUser.email,
      password: selectionUser.password,
    };
    setIsLoginProcessing(true);
    const token = await getAccessToken(
      LoginMethod.LoginForm,
      props.onInvalidUserInfo,
      user
    );
    setIsLoginProcessing(false);
    if (!token) return;

    if (!checkEngineEnabled(token.access_token)) {
      props.onInvalidUserInfo("ENGINE_DISABLED");
      return;
    }
    saveToken(token);

    // アプリ設定一括取得（/analysis/app/settings）と状態保持 実施
    setSettingInfo(user.user_id);
  };

  const onClickEdit = useCallback(
    (user: SelectionLoginUser) => setUserToEdit(user),
    []
  );
  const onClickDelete = useCallback(
    (user: SelectionLoginUser) => setUserToDelete(user),
    []
  );
  const theme = useTheme();
  const boxShadowColor = theme.colors.primary["bg_lv1"];

  return (
    <>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        px={2}
        pt={1}
        pb={4}
      >
        <FormLabel htmlFor="editing-switch" mb={0} mr={1}>
          {t("Login.selectionLogin.edit")}
        </FormLabel>
        <PrimarySwitch
          id="editing-switch"
          onChange={(e) => setIsEditing(e.target.checked)}
        />
      </FormControl>
      <VStack spacing={5} px={2} pb="calc(5% + 5px)">
        {userList?.map((user, userIndex) => (
          <Flex key={userIndex} w="full">
            <Box
              display="inline-block"
              w={isEditing ? "calc(100% - 50px)" : "full"}
              transition="width 0.3s"
            >
              <SelectionLoginUserButton
                user={user}
                disabled={isLoginProcessing}
                onClick={() => login(user)}
              />
            </Box>
            <Flex
              transition="max-width 0.3s"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW={isEditing ? "50px" : 0}
              w="50px"
              overflow="hidden"
              rowGap={2}
            >
              {[
                {
                  Icon: IoCreateOutline,
                  color: "teal",
                  label: "Edit User",
                  onClick: onClickEdit,
                },
                {
                  Icon: IoTrashOutline,
                  color: "red",
                  label: "Delete User",
                  onClick: onClickDelete,
                },
              ].map((item, itemIndex) => (
                <IconButton
                  key={itemIndex}
                  aria-label={item.label}
                  isRound
                  variant="ghost"
                  colorScheme={item.color}
                  boxShadow={`0 3px 10px ${boxShadowColor}`}
                  size="sm"
                  icon={<item.Icon size={20} />}
                  onClick={() => item.onClick(user)}
                />
              ))}
            </Flex>
          </Flex>
        ))}
      </VStack>
      <Box px={{ base: 7, md: 12 }} width="full" maxW="2xl" mb={10}>
        <Button
          variant="btn_primary"
          display="flex"
          alignItems="center"
          width="full"
          rightIcon={<IoAdd size={20} />}
          disabled={isLoginProcessing}
          onClick={() => setIsAdditionFormOpen(true)}
        >
          {t("Login.selectionLogin.addUser")}
        </Button>
      </Box>
      <LoadingOverlay isOpen={isLoginProcessing} />

      {/* ユーザー追加/編集 */}
      <Drawer
        isOpen={isAdditionFormOpen || !!userToEdit}
        placement="bottom"
        blockScrollOnMount={false}
        onClose={() => {
          setIsAdditionFormOpen(false);
          setUserToEdit(null);
        }}
      >
        <DrawerOverlay />
        <DrawerContent
          alignItems="center"
          pb={5}
          backgroundColor="primary.bg_lv1"
        >
          <DrawerCloseButton />
          <DrawerHeader>
            {isAdditionFormOpen
              ? t("Login.selectionLogin.addUser.title")
              : t("Login.selectionLogin.editUser.title")}
          </DrawerHeader>
          <DrawerBody maxW="2xl" w="full">
            <SelectionLoginUserForm
              onCompleted={() => {
                setIsAdditionFormOpen(false);
                setUserToEdit(null);
              }}
              onInvalidUserInfo={props.onInvalidUserInfo}
              userToEdit={userToEdit || undefined}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* ユーザー削除確認 */}
      <SimpleAlertDialog
        isOpen={!!userToDelete}
        ok={t("Login.selectionLogin.deleteUser.yes")}
        cancel={t("Login.selectionLogin.deleteUser.cancel")}
        title={t("Login.selectionLogin.deleteUser.title")}
        description={t("Login.selectionLogin.deleteUser.description")}
        onOk={() => {
          if (userToDelete?.id) {
            DB.deleteUser(userToDelete.id).then();
          }
          setUserToDelete(null);
        }}
        onClose={() => setUserToDelete(null)}
      />
      <Flex gap={2} justifyContent="space-evenly" flexWrap="wrap">
        <GoogleLoginButton onInvalidUserInfo={props.onInvalidUserInfo} />
        {["beta"].includes(import.meta.env.MODE) && <TwooCaLoginButton />}
      </Flex>
    </>
  );
}

function SelectionLoginUserForm(props: {
  onCompleted: () => void;
  onInvalidUserInfo: (errorCode: LoginErrorCode) => void;
  userToEdit?: SelectionLoginUser;
}): ReactElement {
  const t = useModifiedTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<SelectionLoginUser>();
  const addUserToDb = useAddUserToDb();
  const getAccessToken = useGetAccessToken();

  const onSubmit: SubmitHandler<SelectionLoginUser> = async (data) => {
    const user: LoginFormData = {
      user_id: data.email,
      password: data.password,
    };
    const token = await getAccessToken(
      LoginMethod.LoginForm,
      props.onInvalidUserInfo,
      user
    );
    if (!token) return;

    if (props.userToEdit?.id !== undefined) {
      await DB.updateUser(props.userToEdit.id, data);
    } else {
      const success = await addUserToDb(data);
      if (!success) return;
    }
    props.onCompleted();
  };

  const defaultColorIndex = useMemo(() => {
    if (props.userToEdit) {
      const tmpIndex = COLOR_LIST.findIndex(
        (colorItem) => colorItem.color === props.userToEdit?.color
      );
      return tmpIndex >= 0 ? tmpIndex : DEFAULT_COLOR_INDEX;
    } else {
      return DEFAULT_COLOR_INDEX;
    }
  }, [props.userToEdit]);

  return (
    <Flex
      as="form"
      flexDirection="column"
      rowGap={4}
      onSubmit={handleSubmit(onSubmit)}
    >
      <IdInput
        defaultValue={props.userToEdit ? props.userToEdit.email : undefined}
        {...register("email")}
      />
      <PasswordInput
        input={{
          defaultValue: props.userToEdit
            ? props.userToEdit.password
            : undefined,
          ...register("password"),
        }}
      />
      <TextInput
        defaultValue={props.userToEdit ? props.userToEdit.nickname : undefined}
        placeholder={t("Login.nickname")}
        {...register("nickname")}
        bg="common.base"
      />
      <Text textAlign="center">{t("Login.selectColor")}</Text>
      <input
        type="hidden"
        value={COLOR_LIST[defaultColorIndex].color}
        {...register("color")}
      />
      <ColorPicker
        margin="0 auto"
        spacing={{ base: 2, md: 5 }}
        colorList={COLOR_LIST}
        defaultIndex={defaultColorIndex}
        onColorChange={(colorIndex) =>
          setValue("color", COLOR_LIST[colorIndex].color)
        }
      />
      <Button
        variant="btn_primary"
        w="full"
        type="submit"
        disabled={isSubmitting}
      >
        {t("Login.selectionLogin.addUser.submit")}
      </Button>
    </Flex>
  );
}

// 録音開始のエンジン用URL生成

const tabIndexAtom = atomWithStorage("loginPageTab", 0);

export function Login(): ReactElement {
  const [isLoginError, setIsLoginError] = useState(false);
  const [tabIndex, setTabIndex] = useAtom(tabIndexAtom);

  const handleTabsChange = (index: number): void => {
    setTabIndex(index);
  };

  const navigate = useNavigate();

  // ログイン画面に表示する画像のURLをlocalStorageに保持管理用
  const [loginImageInfo] = useAtom(loginImageInfoAtom);
  const [, setHomeSplashDisplay] = useAtom(homeSplashDisplayAtom);
  const [employeeInfo, setEmployeeInfo] = useAtom(employeeInfoAtom);
  const [passwordChangeStatus, setPasswordChangeStatus] = useAtom(
    passwordChangeStatusAtom
  );
  const [isGoogleSSO] = useAtom(isGoogleSSOAtom);

  const t = useModifiedTranslation();

  const [errorCode, setErrorCode] = useState<LoginErrorCode | null>(null);
  const handleInvalidUserInfo = (code: LoginErrorCode): void => {
    setIsLoginError(true);
    setErrorCode(code);
  };

  useWindowSize();

  useEffect(() => {
    setHomeSplashDisplay(true);
    setPasswordChangeStatus(false);
    setEmployeeInfo(employeeInfoInitial);
  }, [setHomeSplashDisplay, setPasswordChangeStatus, setEmployeeInfo]);

  useEffect(() => {
    if (passwordChangeStatus || employeeInfo.employee_id) {
      if (
        employeeInfo.password_flag === true ||
        (employeeInfo.password_flag === false && isGoogleSSO)
      ) {
        navigate("../home");
      } else {
        navigate("../setting/password");
      }
    }
  }, [
    employeeInfo.employee_id,
    employeeInfo.password_flag,
    isGoogleSSO,
    navigate,
    passwordChangeStatus,
  ]);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  return (
    <>
      <VersionCheckModal />
      {/* ログイン後にAPIで取得したアプリのロゴを表示 */}
      <BannarImage imageInfo={loginImageInfo} />
      <Flex flexDirection="column" alignItems="center">
        <Layout>
          <Flex
            position="relative"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Text fontWeight="500">ver.{__APP_VERSION__}</Text>
            {!HIDE_LANGUAGE_SELECTION && (
              <Box position="absolute" right={0}>
                <LanguageSelector to="login" />
              </Box>
            )}
          </Flex>
        </Layout>
        <Tabs
          isFitted
          index={tabIndex}
          onChange={handleTabsChange}
          variant="unstyled"
          display="flex"
          flexDirection="column"
          width="100%"
          maxW="2xl"
          maxH="full"
          h="full"
          overflow="hidden"
        >
          <TabList
            bgColor="login.login_button_bg"
            borderRadius="full"
            mb={{ base: 5, md: 8 }}
          >
            {[t("Login.normalLogin"), t("Login.selectionLogin")].map(
              (tabItem, tabIndex) => (
                <Tab
                  key={tabIndex}
                  fontWeight="bold"
                  fontSize={14}
                  color="common_text.gray"
                  margin={1.5}
                  borderRadius="full"
                  _active={{ backgroundColor: "white" }}
                  sx={{
                    "@media (hover: hover)": {
                      "&:hover": { backgroundColor: "white" },
                    },
                  }}
                  _selected={{
                    color: "common_text.dark",
                    bgColor: "white",
                    margin: 1.5,
                    borderRadius: "full",
                  }}
                >
                  {tabItem}
                </Tab>
              )
            )}
          </TabList>
          <TabPanels maxH="full" h="full" overflow="auto">
            <TabPanel p={1}>
              <NormalLoginForm onInvalidUserInfo={handleInvalidUserInfo} />
            </TabPanel>
            <TabPanel p={1}>
              <SelectionLogin onInvalidUserInfo={handleInvalidUserInfo} />
            </TabPanel>
          </TabPanels>
        </Tabs>

        {/* ログインエラーモーダル */}
        <LoginErrorModal
          isOpen={isLoginError}
          errorCode={errorCode}
          onClose={() => setIsLoginError(false)}
        />
      </Flex>
    </>
  );
}
