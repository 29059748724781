import { ReactElement, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Center,
  Heading,
  Box,
  SimpleGrid,
  Flex,
  VStack,
  Icon,
  Link,
  useTheme,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { IoChevronForward, IoOpenOutline } from "react-icons/io5";
import { IconType } from "react-icons";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import {
  employeeInfoAtom,
  employeeContractAtom,
  footerIndexAtom,
} from "../store";
import { Layout } from "../components/atoms/Layout";
import { Footer } from "../components/atoms/CommonLayout/Footer";

import personCircleImageUrl from "/src/assets/images/base/IoPersonCircle.svg";
import { setSVGFillColor } from "../utils/svgHelper";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";
import { isDebug } from "./calibrationV2";

// レイアウトパーツ：ヘッダータイトル
function PageTitle(): ReactElement {
  const t = useModifiedTranslation();
  return (
    <Heading size="md" textAlign="center" color="text.main_text_lv1">
      {t("Setting.title")}
    </Heading>
  );
}

function SettingButton(props: {
  title: string;
  icon: IconType;
  isDebug?: boolean;
}): ReactElement {
  return (
    <Button
      width="100%"
      height="60px"
      justifyContent="left"
      alignItems="center"
      variant={!props.isDebug ? "btn_primary" : "btn_debug"}
    >
      <Box textAlign="left" marginLeft="4%">
        {props.title}
      </Box>
      <Icon fontSize="30px" ml="auto" marginRight="4%" as={props.icon} />
    </Button>
  );
}

/*
 * 設定画面
 */
export function Setting(): ReactElement {
  const { i18n } = useTranslation();
  const t = useModifiedTranslation();

  const [, setFooterIndex] = useAtom(footerIndexAtom);

  useEffect(() => {
    setFooterIndex(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  // ログインユーザー情報
  const [employeeInfo] = useAtom(employeeInfoAtom);
  /*
  console.log(
    "employee_name:" + JSON.stringify(employeeInfo.employee_name, null, "\t")
  );
  console.log(
    "employee_id:" + JSON.stringify(employeeInfo.employee_id, null, "\t")
  );
   */

  // 外部リンク情報
  const [employeeContract] = useAtom(employeeContractAtom);

  return (
    <>
      <Box>
        <Layout
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Center h={10} position="relative">
            <PageTitle />
          </Center>
          {/* 設定 ユーザーID ユーザー名 */}
          <Box marginTop="30px" color="common_text.dark">
            <Flex
              bg="primary.bg_lv1"
              minHeight="80px"
              rounded="20"
              justify="left"
              align="center"
              backgroundColor="primary.theme_lv3"
            >
              <Box
                fontSize="40px"
                marginLeft="6%"
                color="text.main_text_lv1"
                textAlign="left"
              >
                <ReactSVG
                  src={personCircleImageUrl}
                  beforeInjection={(svg) => {
                    svg.setAttribute("width", "40px");
                    svg.setAttribute("height", "40px");
                    setSVGFillColor(svg, ".cls-1", "white");
                    setSVGFillColor(
                      svg,
                      ".cls-2",
                      theme.colors.primary["theme_lv1"]
                    );
                  }}
                />
              </Box>
              <Box marginLeft="6%">
                <VStack spacing={0.5} w="100%">
                  <Box
                    w="100%"
                    fontSize="15px"
                    textAlign="left"
                    fontFamily="Oswald"
                    fontWeight="400"
                  >
                    {employeeInfo.employee_id}
                  </Box>
                  <Box
                    w="100%"
                    fontSize="16px"
                    textAlign="left"
                    fontWeight="400"
                  >
                    {employeeInfo.employee_name}
                  </Box>
                </VStack>
              </Box>
            </Flex>

            <SimpleGrid columns={{ base: 1, md: 1 }} spacingY="20px" mt={5}>
              <Link
                as={RouterLink}
                to={`/${i18n.language}/` + "setting/password"}
              >
                <SettingButton
                  title={t("Setting.itemTitlePasswordChange")}
                  icon={IoChevronForward}
                />
              </Link>
            </SimpleGrid>

            <Box color="text.main_text_lv1" marginTop="10" marginBottom="5">
              {t("Setting.subTitleApp")}
            </Box>

            <SimpleGrid columns={{ base: 1, md: 1 }} spacingY="20px">
              {/* 古いキャリブレーション画面 */}
              <Link as={RouterLink} to={`/${i18n.language}/` + "calibration"}>
                <SettingButton
                  title={t("Setting.itemTitleCalibration")}
                  icon={IoChevronForward}
                />
              </Link>
              {/* 新しいキャリブレーション画面 */}
              {isDebug && (
                <Link
                  as={RouterLink}
                  to={`/${i18n.language}/` + "calibration_new"}
                >
                  <SettingButton
                    title={t("Setting.itemTitleCalibration2")}
                    icon={IoChevronForward}
                    isDebug
                  />
                </Link>
              )}
              <Link
                as={RouterLink}
                to={`/${i18n.language}/` + "setting/color_layout"}
              >
                <SettingButton
                  title={t("Setting.itemTitleColorLayout")}
                  icon={IoChevronForward}
                />
              </Link>
            </SimpleGrid>

            <Box color="text.main_text_lv1" marginTop="10" marginBottom="5">
              {t("Setting.subTitleOther")}
            </Box>

            <SimpleGrid columns={{ base: 1, md: 1 }} spacingY="20px">
              <Link href={employeeContract.privacy_policy} isExternal>
                <SettingButton
                  title={t("Setting.itemTitlePrivacyPolicy")}
                  icon={IoOpenOutline}
                />
              </Link>
              <Link href={employeeContract.terms_of_service} isExternal>
                <SettingButton
                  title={t("Setting.itemTitleTermsOfService")}
                  icon={IoOpenOutline}
                />
              </Link>
            </SimpleGrid>

            <Box
              color="text.main_text_lv1"
              marginTop="10"
              textAlign="center"
              fontFamily="Oswald"
              fontWeight="300"
            >
              {t("Setting.version")} {__APP_VERSION__}(
              {__GIT_DESCRIBE_REVISION__})
            </Box>
          </Box>
          <Box height="150px">&nbsp;</Box>
        </Layout>
      </Box>
      <Footer />
    </>
  );
}
