import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ImageButton } from "../atoms/ImageButton";
import TwoocaSigninJpUrl from "/src/assets/images/twooca/twooca_login_jp.svg";
import TwoocaSigninEnUrl from "/src/assets/images/twooca/twooca_login_en.svg";

export function TwooCaLoginButton(): JSX.Element {
  const { i18n } = useTranslation();
  const twoocaAuthUri = import.meta.env.VITE_APP_TWOOCA_AUTH_URL;
  const clientId = import.meta.env.VITE_APP_TWOOCA_CLIENT_ID;
  const redirectUri = encodeURIComponent(
    `${window.location.origin}/twooca/callback`
  );

  const signinLogoUrl = useMemo(() => {
    if (i18n.language == "ja") return TwoocaSigninJpUrl;
    return TwoocaSigninEnUrl;
  }, [i18n.language]);

  const redirectToAuth = (): void => {
    const redirectTo = `${twoocaAuthUri}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=`;
    window.location.href = redirectTo;
  };

  return (
    <ImageButton
      aria-label="with-twooca"
      imageSrc={signinLogoUrl}
      maxWidth={400}
      minWidth={200}
      onClick={redirectToAuth}
    />
  );
}
